<script setup>

import {computed, inject} from "vue";
import {Head, usePage} from "@inertiajs/vue3";
import Default from "@/Layouts/Default.vue";

defineOptions({layout: Default})

const props = defineProps({
    apps: Array
})

const page = usePage()
const user = computed(() => page.props.auth.user)

const eventBus = inject('eventBus')
eventBus.emit('navBarChanged', {title: 'Apps'})
</script>

<template>
    <Head title="Apps"/>

    <v-container
        fluid
    >
        <v-row>
            <v-col
                :key="app.id"
                v-for="app in apps"
                cols="3"
            >
                <a
                    :href="app.url"
                    target="_blank"
                >
                    <v-card
                        elevation="5"
                        :style="{border: '8px solid ' + app.color}"
                        height="100%"
                    >
                        <v-img
                            class="ma-5"
                            max-width="100%"
                            height="50px"
                            :src="app.thumbnail_url"
                        />

                        <v-card-title class="font-weight-bold">
                            {{ app.name }}
                        </v-card-title>

                        <v-card-text>
                            {{ app.description }}
                        </v-card-text>
                    </v-card>
                </a>
            </v-col>
        </v-row>

    </v-container>
</template>
